import React from 'react';
import PropTypes from 'prop-types';

import useText from 'hooks/useText';

import { PROMO_SECTION_TYPE } from 'constants/common.constants';

const Statistics = ({
    data
}) => {

    const { t } = useText();

    const statisticsData = data ? JSON.parse(data) : {};

    const activeStatistics = Object.keys(statisticsData).filter(t => statisticsData[t]);

    return (
        <div className="rt--promo-statistics" id={PROMO_SECTION_TYPE.STATISTICS}>
            <div className='rt--promo-statistics-block rt--promo-block-gap'>
                <div className='rt--promo-statistics-block-content rt--flex rt--flex-wrap rt--align-center rt--justify-center'>
                    {
                        activeStatistics.map(statistic => (
                            <div
                                key={statistic}
                                className='rt--promo-statistics-block-content-info rt--background-color rt--flex rt--flex-col rt--align-center rt--justify-center'
                            >
                                <span className='rt--subtitle-color rt--font-16 rt--font-regular rt--mb-2'>
                                    {
                                        t(PROMO_SECTION_TYPE.STATISTICS, `title${statistic}`)
                                    }
                                </span>
                                <span className='rt--theme-color rt--font-32 rt--font-extra-bold'>
                                    {
                                        t(PROMO_SECTION_TYPE.STATISTICS, `description${statistic}`)
                                    }
                                </span>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

Statistics.propTypes = {
    data: PropTypes.string
}


export default Statistics; 